import React from 'react';
import {graphql} from 'gatsby';
import {AlgoliaMini} from '../../app/shared/components/algolia-mini/AlgoliaMini.component';
import s from '../../app/knowledge-base/+posts-archive/PostsArchive.module.scss';
import {useIntl} from 'gatsby-plugin-intl';
import {TemplateHelper} from '@helpers/templates.helper';
import {useLocation} from '@reach/router'
import {Breadcrumbs} from '../../app/shared/components/breadcrumbs/Breadcrumbs.component';
import {Banner} from '../../app/knowledge-base/banner/Banner.component';
import {PostLink} from '../../app/knowledge-base/+posts-archive/components/post-link/PostLink.component';
import { StoreSearch } from '@components/store-search/StoreSearch.component';
import { getLink } from '@helpers/linking.helper';
import { PageType } from '@constants/pageType.constants';
import { ThumbnailsHelper } from '@helpers/thumbnails.helper';
import { coversDictionary } from '@helpers/covers.helper';
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import { useLocalizedDynamicRoute } from '../../hooks/use-localized-dynamic-route';
import { useSiteMetadata } from '../../hooks/use-static-metadata';
import { getLocalizedSlug } from '@helpers/knowledgeBase.helper';

interface Headers {
  article: string;
  video: string;
  guide: string;
}

interface Descriptions {
  article: string;
  video: string;
  guide: string;
}

const Articles = (props) => {

  const {data} = props;

  const {allWpArticle, allWpGuide, allWpVideo} = data;

  const intl = useIntl();
  const location = useLocation();
  const search = location.search;
  const parameters = new URLSearchParams(search);
  const type = (parameters.get('type') || 'article') as keyof Headers;

  const allPosts = [allWpArticle, allWpGuide, allWpVideo];
  const allFlatPosts = allPosts.map(TemplateHelper.getEdges).flat(Infinity);
  const allSortedPosts = allFlatPosts.sort(TemplateHelper.sortByDatePosts);
  const allSortedPostsFilterByType = allSortedPosts.filter(edge => edge.node.contentTypeName === type);
  const midpoint = Math.floor(allSortedPostsFilterByType.length / 2);
  const firstHalf = allSortedPostsFilterByType.slice(0, midpoint);
  const lastHalf = allSortedPostsFilterByType.slice(midpoint);

  const link = useLocalizedDynamicRoute();
  const { siteUrl } = useSiteMetadata();
  
  const headers: Headers = {
    article: intl.formatMessage({id: 'postArchives__articlesHeader'}),
    video: intl.formatMessage({id: 'postArchives__videosHeader'}),
    guide: intl.formatMessage({id: 'postArchives__guidesHeader'})
  };

  const descriptions: Descriptions = {
    article: intl.formatMessage({id: 'postArchives__articlesDescription'}),
    video: intl.formatMessage({id: 'postArchives__videosDescription'}),
    guide: intl.formatMessage({id: 'postArchives__guidesDescription'})
  };

  return (
    <>
      <GatsbySeo
        htmlAttributes={{
          language: intl.locale,
        }}
        languageAlternates={[
          {
            hrefLang: 'x-default',
            href: `https://avionaut.com${getLink(intl.locale, PageType.ARTICLE)}/`,
          },
          {
            hrefLang: 'en',
            href: `https://avionaut.com${getLink('en', PageType.ARTICLE)}/`,
          },
          {
            hrefLang: 'pl',
            href: `https://avionaut.com${getLink('pl', PageType.ARTICLE)}/`,
          },
          {
            hrefLang: 'de',
            href: `https://avionaut.com${getLink('de', PageType.ARTICLE)}/`,
          },
          {
            hrefLang: 'cz',
            href: `https://avionaut.com${getLink('cz', PageType.ARTICLE)}/`,
          },
          {
            hrefLang: 'fr',
            href: `https://avionaut.com${getLink('fr', PageType.ARTICLE)}/`,
          },
        ]}
        canonical={siteUrl+link(PageType.KNOWLEDGE_BASE_ARTICLES)+'/'}
        title={intl.formatMessage({ id: 'common__knowledgeBaseTitle' })}
        description={intl.formatMessage({ id: 'common__knowledgeBaseDescription' })}
        metaTags={[
          {
            property: 'thumbnail',
            content: ThumbnailsHelper[intl.locale],
          },
          {
            property: 'og:title',
            content: intl.formatMessage({ id: 'common__knowledgeBaseTitle' }),
          },
          {
            property: 'og:image',
            content: coversDictionary[intl.locale],
          },
          {
            property: 'og:description',
            content: intl.formatMessage({ id: 'common__knowledgeBaseDescription' }),
          },
          {
            property: 'twitter:title',
            content: intl.formatMessage({ id: 'common__knowledgeBaseTitle' }),
          },
          {
            property: 'twitter:description',
            content: intl.formatMessage({ id: 'common__knowledgeBaseDescription' }),
          },
        ]}
      />
      <AlgoliaMini/>
      <div className={s.postsArchive__wrapper}>
        <Breadcrumbs crumbs={[
          {name: intl.formatMessage({id: 'common__knowledgeBase'}), path: '../'},
          {name: intl.formatMessage({id: 'common__articles'}), path: './'},
        ]}/>
        <h1 className={s.postsArchive__header}>{headers[type]}</h1>

        <p className={s.postsArchive__description}
           dangerouslySetInnerHTML={{__html: descriptions[type]}}
        />

      </div>

      {firstHalf.map(edge => (
        <PostLink key={edge.node.id} post={edge.node}/>
      ))}

      <Banner banner={data.bannerA}/>

      {lastHalf.map(edge => (
        <PostLink key={edge.node.id} post={edge.node}/>
      ))}
      <StoreSearch
        header={intl.formatMessage({ id: 'product__storeSearchHeader' })}
        fieldText={intl.formatMessage({ id: 'product__storeSearchPlaceholder' })}
        shopsURL={getLink(intl.locale, PageType.SHOPS)}
        currentLocale={intl.locale}
        isBackgroundVisible
      />
    </>
  )
};


export const query = graphql`
  query AllTypesArticles($locale: String) {
    allWpArticle(
      sort: {fields: date, order: DESC}
      filter: {locale: {locale: {eq: $locale}}}
    ) {
      edges {
        node {
          id
          contentTypeName
          slug
          title
          date
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
          }
        }
      }
    }
    allWpGuide(
      sort: {fields: date, order: DESC}
      filter: {locale: {locale: {eq: $locale}}}
    ) {
      edges {
        node {
          id
          contentTypeName
          slug
          title
          date
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
          }
        }
      }
    }
    allWpVideo(
      sort: {fields: date, order: DESC}
      filter: {locale: {locale: {eq: $locale}}}
    ) {
      edges {
        node {
          id
          contentTypeName
          slug
          title
          date
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
          }
        }
      }
    }
    bannerA:   allWpBanner(filter: {slug: {eq: "banner-a"}, locale: {locale: {eq: $locale}}}) {
      edges {
        node {
          id
          bannerProperties {
            bannerImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
            bannerMobileImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
            bannerUrl
          }
        }
      }
    }
  }
`
export default Articles;
