const getNode = (obj) => {
  if (obj.hasOwnProperty('node')) return obj.node;
  else return obj;
};

const getEdges = (obj) => {
  if (obj.hasOwnProperty('edges')) return obj.edges;
  else return obj;
};

const sortByDatePosts = (a, b) => {

  const dateA = new Date(a.date).getTime();
  const dateB = new Date(b.date).getTime();
  
  if (dateB > dateA) {
    return 1
  } else {
    if (dateA > dateB) return -1
  }
  return 0;
};

export const TemplateHelper = {
  getNode,
  getEdges,
  sortByDatePosts
}
