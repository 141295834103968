import React from 'react';
import s from './PostLink.module.scss';
import {GatsbyImage, getImage, Placeholder} from 'gatsby-plugin-image';
import {IWpPostWithSlug} from '../../../../../types/wordpress.types';
import {useIntl} from 'gatsby-plugin-intl';
import PublishedDate from '../../../card/published-date/PublishedDate.component';
import {Link} from 'gatsby';
import multilingualSlugs from '../../../../../intl/slugs.json';
import {PageType} from '@constants/pageType.constants';
import {i18nPrefixCode} from '@helpers/linking.helper';

interface IPostLink {
  post: IWpPostWithSlug
}

export const PostLink = ({post}: IPostLink) => {

  const intl = useIntl();

  if (!post) return


  const routeArticle =
    i18nPrefixCode(intl.locale) +
    multilingualSlugs[intl.locale][PageType.KNOWLEDGE_BASE] +
    multilingualSlugs[intl.locale][PageType.ARTICLE]


  const publishDate = post.date;

  return (
    <>
      <div className={s.postLink}>
        <div className={s.postLink__image}>
          <Link
            to={
              routeArticle +
              '/' + post.slug
            }
          >
            <GatsbyImage
              alt={
                post.featuredImage ?
                  post.featuredImage.node.altText :
                  intl.formatMessage({id: 'common__defaultVideoAlt'}
                  )}
              image={
                post.featuredImage ?
                  getImage(post.featuredImage.node.localFile) :
                  Placeholder
              }
              className={s.postLink__imageImg}
            />
          </Link>
        </div>
        <div className={s.postLink__info}>
          <Link
            to={
              routeArticle +
              '/' + post.slug
            }
          >
            <h2 className={s.postLink__title}>{post.title}</h2>
          </Link>
          <div className={s.postLink__publishDate}>
            {publishDate &&
              <div className={s.postLink__publish}>
                <PublishedDate publishedDate={publishDate} isAuthorAvailable={false}/>
              </div>
            }
          </div>
        </div>
      </div>
    </>
  );
};
